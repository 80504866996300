import { BREAKPOINTS } from '@/components/ds/breakpoints'
import { DSFlex } from '@/components/ds/Flex/Flex'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { DSButton, DSText } from '@zoe/ds-web'
import { DSTextAndIcon } from '@/components/ds/TextAndIcon/TextAndIcon'
import { CurrencyCode } from '@/lib/checkout/types'
import { formatCurrency } from '@/lib/checkout/utils'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import { DSBadge } from '@zoe/ds-web'
import React from 'react'
import styled from 'styled-components'

type D30PricingCardProps = {
  id: string
  onClick: () => void
  title: string
  price: number
  previousPrice?: number
  currency?: CurrencyCode
  featureList?: string[]
  isSelected: boolean
  ctaText?: string
  ctaLink?: string
  tagline?: string
  disabled?: boolean
  analyticsLinkText?: string
  label?: string
}

const StyledCard = styled.div<{ $active: boolean }>`
  background-color: ${({ $active }) => ($active ? 'var(--color-surface-cream-subtle)' : 'white')};
  border: 1px solid var(--color-border-secondary);
  border-radius: 8px;
  cursor: ${({ $active }) => ($active ? 'pointer' : 'default')};
  max-height: ${({ $active }) => ($active ? '1000px' : '300px')};
  max-width: 600px;
  overflow: hidden;
  padding: var(--grid-24) var(--grid-16);
  transition:
    background-color,
    max-height 0.6s ease-in-out;
`

const StyledPricingContainer = styled.div<{ $translate?: boolean }>`
  @media screen and (max-width: ${BREAKPOINTS.m}) {
    ${({ $translate }) => $translate && `transform: translateY(50%);`}
  }
`

export const D30PricingCard: React.FC<D30PricingCardProps> = ({
  id,
  onClick,
  title,
  price,
  label,
  previousPrice,
  currency = 'gbp',
  isSelected,
  ctaText,
  ctaLink,
  tagline,
  featureList,
  disabled = false,
  analyticsLinkText,
}) => {
  const handleCtaClick = () => {
    const linkText = analyticsLinkText ?? `${title.toLowerCase()}-Subscribe now`
    trackCtaClick('d30', linkText, {
      link_position: 'page_body',
      page_name: 'pdp',
    })
  }

  return (
    <StyledCard
      id={`pricing-card-${id}`}
      data-testid={`pricing-card-${id}`}
      $active={isSelected && !disabled}
      onClick={disabled ? undefined : onClick}
    >
      <DSFlex direction="row" alignItems="flex-end" justifyContent="space-between">
        <DSFlex
          alignItems="center"
          gap={12}
          breakConfig={{
            breakAt: { breakPoint: 'm', direction: 'column-reverse', gap: 12, alignItems: 'flex-start' },
            maxViewportDirection: 'row',
          }}
        >
          {disabled ? (
            <DSText variant="fluid-paragraph-300" weight="semiBold" color="primary">
              {title}
            </DSText>
          ) : (
            <DSTextAndIcon
              iconName={isSelected ? 'radio-selected' : 'radio-unselected'}
              iconPosition="left"
              iconSize={24}
              text={title}
              textVariant="fluid-paragraph-300"
              textWeight="semiBold"
              textColour="primary"
            />
          )}
          {label && (
            <DSBadge surfaceColor="yellow-bold" textColor="primary">
              {label}
            </DSBadge>
          )}
        </DSFlex>
        <DSSpacer size={12} direction="horizontal" />
        <StyledPricingContainer $translate={!!label && !!previousPrice}>
          <DSFlex
            breakConfig={{
              breakAt: { breakPoint: 'm', direction: 'column', gap: 0 },
              maxViewportDirection: 'row-reverse',
            }}
          >
            <DSText as="span" variant="fluid-paragraph-300" weight="semiBold">
              {' '}
              {formatCurrency(currency, price, true)}
            </DSText>
            {previousPrice && (
              <>
                <DSSpacer size={8} direction="horizontal" />
                <DSText as="s" variant="fluid-paragraph-300" weight="light" color="placeholder">
                  {formatCurrency(currency, previousPrice, true)}
                </DSText>
              </>
            )}
          </DSFlex>
        </StyledPricingContainer>
      </DSFlex>
      <DSSpacer size={12} direction="vertical" />
      {featureList?.map((feature, i) => (
        <React.Fragment key={`feature-${i}`}>
          <DSTextAndIcon
            iconName="checkbox-tick"
            iconPosition="left"
            iconSize={16}
            text={feature}
            textColour="primary"
            textVariant="fluid-paragraph-200"
            textWeight="regular"
          />
          {i < featureList.length - 1 && <DSSpacer size={8} direction="vertical" />}
        </React.Fragment>
      ))}
      {isSelected && !disabled && (
        <DSFlex direction="column" alignItems="center">
          <DSSpacer direction="vertical" size={16} />
          {ctaLink && ctaText && (
            <a href={ctaLink} aria-label={ctaText}>
              <DSButton
                size="large"
                variant="primary"
                width="full"
                colorScheme="default"
                onPress={handleCtaClick}
                testId={`cta-button-${id}`}
              >
                {ctaText}
              </DSButton>
            </a>
          )}
          <DSSpacer direction="vertical" size={16} />
          {tagline && (
            <DSText as="span" variant="fluid-paragraph-200" color="secondary">
              {tagline}
            </DSText>
          )}
        </DSFlex>
      )}
    </StyledCard>
  )
}
